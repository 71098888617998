// @ts-ignore
import template from './annual-earnings.component.html';
import { IMetricsResourceService } from 'models/metrics-resource.model';
import { IAnnualEarningsChartItem } from '../annual-earnings-chart/annual-earnings-chart.component';
import { pluck } from 'underscore';
import { IMoney } from '../../../../models/money.model';
import { Money } from '../../../money/money';

type TotalEarningsLabelMode = 'annual' | 'months' | 'predictions' | 'none';

export const MetricsAnnualEarningsComponent = {
  template,
  controller: class MetricsAnnualEarningsController {
    isLoading = true;
    selectedYear: string = new Date().getFullYear().toString();
    minYear: string;
    maxYear: string;
    totalEarnings: IMoney;
    totalUnaccepted: IMoney;
    totalEarningsLabelMode: TotalEarningsLabelMode = 'none';
    totalEarningsMonthCount: number;

    allItems: IAnnualEarningsChartItem[] = [];
    yearData: IAnnualEarningsChartItem[] = [];

    constructor(
      private Metrics: IMetricsResourceService
    ) {
      'ngInject';
    }

    $onInit() {
      this.Metrics.getAnnualEarnings().$promise.then((response) => {
        this.allItems = response.items;

        // Get min and max available years
        const sortedValues = pluck(this.allItems, 'key').sort();
        const currentYear = new Date().getFullYear().toString();
        this.minYear = sortedValues.length ? sortedValues[0].slice(0, 4) : currentYear;
        this.maxYear = sortedValues.length ? sortedValues.reverse()[0].slice(0, 4) : currentYear;

        this.prepareData();
        this.isLoading = false;
      });
    }

    handleYearChange(year: string) {
      this.selectedYear = year;

      this.prepareData();
    }

    private prepareData() {
      const allYearData: IAnnualEarningsChartItem[] = [];
      const predictedEarningsOnlyYearData: IAnnualEarningsChartItem[] = [];
      const earningsOnlyYearData: IAnnualEarningsChartItem[] = [];
      this.allItems.forEach((item) => {
        if (!item.key.startsWith(this.selectedYear)) {
          return;
        }

        allYearData.push(item);

        if (item.key.indexOf('unaccepted') !== -1) {
          predictedEarningsOnlyYearData.push(item);
        } else {
          earningsOnlyYearData.push(item);
        }
      });

      this.yearData = allYearData;

      // Update totalEarnings label mode
      this.totalEarningsLabelMode = (() => {
        // If no data or one month only
        if (!allYearData.length || earningsOnlyYearData.length === 1) {
          return 'none';
        }

        // IF no predictions that means all data are earnings
        if (earningsOnlyYearData.length && earningsOnlyYearData.length === allYearData.length) {
          return 'annual';
        } else if (earningsOnlyYearData.length) {
          this.totalEarningsMonthCount = earningsOnlyYearData.length;

          return 'months';
        }

        return 'predictions';
      })() as TotalEarningsLabelMode;

      // Sum capacity and convert to Money if label mode is not 'none'
      if (this.totalEarningsLabelMode !== 'none') {
        const totalEarnings = pluck(earningsOnlyYearData, 'amount').reduce((acc, i) => acc + i, 0);
        const totalUnaccepted = pluck(predictedEarningsOnlyYearData, 'amount').reduce((acc, i) => acc + i, 0);
        this.totalEarnings = Money.createFromEuros(totalEarnings);
        this.totalUnaccepted = Money.createFromEuros(totalUnaccepted);
      }
    }
  }
};
