// @ts-ignore
import template from './balance-widget.component.html';
import { Money } from 'modules/money/money';
import { StateService } from '@uirouter/angularjs';
import { CapacityService } from '../capacity.service';
import { UserManagerService } from '../../user-manager/user-manager.service';
import { ICapacityServiceValue } from '../../../models/http/account-capacity-http.model';

export const BalanceWidgetComponent: ng.IComponentOptions = {
  template,
  bindings: {
    isBalanceHidden: '<'
  },
  controller: class BalanceWidgetController {
    // Bindings
    // @ts-ignore
    isBalanceHidden = this.isBalanceHidden;

    isAnimationEnabled = false;
    isFreelancer = false;
    capacity: ICapacityServiceValue;

    constructor(
      private $state: StateService,
      private $window: ng.IWindowService,
      private CapacityService: CapacityService,
      private UserManager: UserManagerService
    ) {
      'ngInject';

      this.capacity = this.CapacityService.data;

      // On initial page load/refresh we don't want to see animation (numbers are visible for a half-second)
      // So enable animation only after some time
      setTimeout(() => {
        this.isAnimationEnabled = true;
      }, 1000);
    }

    $onInit() {
      this.isFreelancer = this.UserManager.data.isTeamMember ||
        this.UserManager.data.isHiredGun ||
        this.UserManager.data.accountType === 'team_member';
    }

    get hasBooked() {
      return this.$state.includes('projects') &&
        Money.isGreaterThanZero(this.capacity.booked.totalBooked);
    }

    get hasExpectedPayments() {
      if (!this.capacity.expectedPayments) {
        return false;
      }

      return !this.capacity.expectedPayments.isZero();
    }

    get hasPendingDelivered() {
      if (!this.capacity.awaitingAcceptance) {
        return false;
      }

      return Money.isGreaterThanZero(this.capacity.awaitingAcceptance.totalDelivered);
    }

    get isFreelancerView() {
      return this.isFreelancer && !this.CapacityService.impersonalizedUser && !this.$state.includes('projects');
    }

    getDeliveringProgress(magnifyToZero = false) {
      const percentage = Math.round(this.capacity.booked.totalDelivered.getAmount() /
        this.capacity.booked.totalBooked.getAmount() * 100);

      // When magnifyToZero do not show extremely low percentage (looks ugly)
      return percentage < 2 && magnifyToZero ? '0%' : `${percentage}%`;
    }

    getNamePossessiveSuffix(name: string) {
      return name[name.length - 1] === 's' ? `${name}'` : `${name}'s`;
    }
  }
};
