import angular from 'angular';

export default angular.module('app.resources.capacity', [
  require('angular-resource')
])
  .service('Capacity', function ($resource) {
    'ngInject';

    return $resource('/api/capacity', {}, {
      getAll: {
        url: '/api/capacity/all',
        isArray: true
      },
      getPendingDelivered: {
        url: '/api/capacity/pending-delivered'
      },
    });
  })
  .name;
