import template from './team-card.component.html';

export const TeamCardComponent = {
  bindings: {
    project: '<',
    team: '<',
    theme: '@'
  },
  template,
  controller: class TeamCardController {
    constructor($state, TeamsModalsService, UrlService, UserManager) {
      'ngInject';

      this.$state = $state;
      this.TeamsModalsService = TeamsModalsService;
      this.UrlService = UrlService;
      this.UserManager = UserManager;
    }

    $onInit() {
      this.theme = this.theme || 'gray';
    }

    get isNavigatable() {
      return !this.project;
    }

    get ngClass() {
      const ngClass = [`team-card_theme_${this.theme}`];

      if (this.isNavigatable) {
        ngClass.push('team-card_clickable');
      }

      return ngClass;
    }

    goToTeamPage(team) {
      if (!this.isNavigatable) {
        return;
      }

      const name = this.UrlService.slugify(team.name);
      this.$state.go('teams.view', { id: team._id, name });
    }

    showHireTeamModal(autoInviteTeam) {
      if (this.showHireTeamModal.isInProgress) {
        return;
      }

      this.showHireTeamModal.isInProgress = true;

      this.TeamsModalsService.openHireModal(
        this.team,
        this.project,
        autoInviteTeam
      ).result
        .finally(() => {
          this.showHireTeamModal.isInProgress = false;
        });
    }
  }
};
