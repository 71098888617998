import * as angular from 'angular';
import UiRouterModule, { StateProvider } from '@uirouter/angularjs';

import AuthenticationModule from 'modules/authentication';
import PageMetaModule from 'modules/common/page-meta-service';
import TagResourceModule from 'modules/resources/tag';
import TeamResourceModule from 'modules/resources/team';
import UrlModule from 'modules/common/url.module';
import UserManagerModule from 'modules/user-manager';

import { ITagResourceService, ITeamResourceService } from 'interfaces';

import { EditTeamDescriptionComponent } from './edit-team-description.component';
import { EditTeamNameComponent } from './edit-team-name.component';
import { EditTeamTagsComponent } from './edit-team-tags.component';
import { TeamVisitCardComponent } from './team-visit-card.component';
import { TeamsVisitCardsListComponent } from './teams-visit-cards-list.component';

export default angular.module('app.teams.visit-card', [
  UiRouterModule,
  AuthenticationModule,
  PageMetaModule,
  TagResourceModule,
  TeamResourceModule,
  UrlModule,
  UserManagerModule,
])
  .config(($stateProvider: StateProvider) => {
    'ngInject';

    $stateProvider.state('teams.visit-card', {
      url: '/:name',
      component: 'teamsVisitCardsList',
      resolve: {
        allTags: (Tag: ITagResourceService) => {
          return Tag.query().$promise;
        },
        teams: ($window: ng.IWindowService, Team: ITeamResourceService) => {
          return Team.query({
            cache: $window.localStorage.resourceCacheBuster,
            populate: 'members.user',
            stats: true
          }).$promise;
        }
      },
      reloadOnSearch: false,
      data: {
        segmentName: 'teams card',
      }
    });
  })
  .component('teamsVisitCardsList', TeamsVisitCardsListComponent)
  .component('teamVisitCard', TeamVisitCardComponent)
  .component('editTeamDescription', EditTeamDescriptionComponent)
  .component('editTeamName', EditTeamNameComponent)
  .component('editTeamTags', EditTeamTagsComponent)
  .name;
